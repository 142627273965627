:root {
  --page-gap-horizontal: 60px;
  --page-gap-vertical: 60px;
  --section-gap: 30px;
}

.section h2 {
  font-size: 40px;
}

#leadspace #logo {
  width: 32px;
  height: 32px;
}

#leadspace #introduction h1 {
  font-size: 50px;
}

#leadspace #introduction p {
  font-size: 18px;
}

#leadspace #introduction nav {
  font-size: 20px;
}

#leadspace #photo {
  height: auto;
  padding-top: 100%;
}

#magzines {
  grid-template-columns: repeat(2, 1fr);
}

#footer {
  font-size: 18px;
}

/*# sourceMappingURL=index.fb220a59.css.map */
