:root {
    --page-gap-horizontal: 60px;
    --page-gap-vertical: 60px;
    --section-gap: 30px;
}

.section {
    h2 {
        font-size: 40px;
    }
}

#leadspace {
    #logo {
        width: 32px;
        height: 32px;
    }

    #introduction {
        h1 {
            font-size: 50px;
        }

        p {
            font-size: 18px;
        }

        nav {
            font-size: 20px;
        }
    }

    #photo {
        padding-top: 100%;
        height: auto;
    }
}

#magzines {
    grid-template-columns: repeat(2, 1fr);
}

#footer {
    font-size: 18px;
}
